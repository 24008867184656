import React, { Component } from "react";
import { Table, Column, InfiniteLoader, AutoSizer } from "react-virtualized";
import { Scrollbars } from "react-custom-scrollbars-2";
import { connect } from "react-redux";
import * as _ from "lodash";
import NumAbbr from "number-abbreviate";
import { contextMenu } from "react-contexify";

import Symbol from "../shared/Symbol";
import LULD from "../shared/LULD";

import { DiscoveryActions } from "../store";

import API from "../api";

import {
  DEFAULT_NEWS_CONFIG,
  SQUEEZE_SORT_LABEL,
  MONEYFLOW_CONTEXT_MENU_ID,
  isPro,
  isProNew,
  isProPlusNew,
  isActiveSubscription,
  TREND_CONTEXT_MENU_ID,
  ALERT_CONTEXT_MENU_ID,
  VOLUME_CONTEXT_MENU_ID,
  DEFAULT_DISCOVERY_COLUMNS,
  DISCOVERY_DISPLAY_PERCENT_CHANGE,
  TIMEFRAME_TRANSFORM_MAP,
  TRADE_COUNT_CONTEXT_MENU_ID,
} from "../constants";
import { checkModified, isRegularMarketOpen } from "../util";

import "react-virtualized/styles.css";
import "./DiscoveryTable.scss";

const moment = require("moment-timezone");

let contextTrigger = null;
// let alertContextTrigger = null;

const numAbbr = new NumAbbr(["k", "m"], 2);
const numAbbrExt = new NumAbbr(["k", "m", "b"], 2);
const numAbbrExt2 = new NumAbbr(["k", "m", "b", "t"], 2);

class DiscoveryTable extends Component {
  constructor() {
    super();

    this.state = {
      scrollTop: 0,
    };

    this.loadMore = this.loadMore.bind(this);
    this._rowClassName = this._rowClassName.bind(this);

    this.visibleSymbols = [];
    this.viewportStartIndex = 0;
    this.viewportStopIndex = 0;
    this.viewportTrackingTimerId;
  }

  componentDidMount() {
    this.viewportTrackingTimerId = setInterval(
      () => this.onCheckViewportSymbols(),
      2000
    );
  }

  componentWillUnmount() {
    const { widget, setViewportSymbols } = this.props;
    setViewportSymbols({
      widget,
      symbols: [],
    });
    this.viewportTrackingTimerId && clearInterval(this.viewportTrackingTimerId);
    this.viewportTrackingTimerId = null;
  }

  onViewportChanged(range) {
    const {
      /* overscanStartIndex, overscanStopIndex , */ startIndex,
      stopIndex,
    } = range;
    this.viewportStartIndex = startIndex;
    this.viewportStopIndex = stopIndex;
  }

  onCheckViewportSymbols() {
    const { discoveryData, widget, setViewportSymbols } = this.props;
    const symbols = [];
    for (
      let i = this.viewportStartIndex;
      i <= this.viewportStopIndex && i < discoveryData?.length;
      i++
    ) {
      symbols.push(discoveryData[i].symbol);
    }
    if (checkModified(this.visibleSymbols, symbols)) {
      setViewportSymbols({
        widget,
        symbols,
      });
    }
    this.visibleSymbols = symbols;
  }

  loadMore() {
    return new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
    });
  }

  _round = (value, decimals) => parseFloat(value).toFixed(decimals);

  _renderCell(symbol, data, type, decimals) {
    const roundedValue = isNaN(data) ? "__" : this._round(data, decimals);
    return (
      <div
        style={{
          color:
            roundedValue > 0
              ? "#00d25b"
              : roundedValue < 0
                ? "#fc424a"
                : "#9B9B9C",
        }}
      >
        {isNaN(data)
          ? "__"
          : roundedValue > 0
            ? `+${roundedValue}${type}`
            : `${roundedValue < 0 ? roundedValue + type : "-"}`}
      </div>
    );
  }

  _renderCellUVol(symbol, data, type, decimals) {
    const roundedValue = isNaN(data) ? "__" : this._round(data, decimals);
    return (
      <div style={{ color: "#9B9B9C" }}>
        {isNaN(data)
          ? "__"
          : roundedValue > 0
            ? `+${roundedValue}${type}`
            : `-`}
      </div>
    );
  }

  _renderCellSqueeze(symbol, data) {
    return data == 0 ? (
      <span
        className="badge-toggle-indicator active"
        style={{ width: "10px", height: "10px" }}
      ></span>
    ) : (
      <span style={{ color: "#9B9B9C" }}>{data}</span>
    );
  }

  _renderCellTrend(symbol, data) {
    if (!data["trend"] || data["trend"][0] === null) {
      return null;
    }
    return (
      <div className={`trend-wrapper ${data["trend"][0] ? "trending" : ""}`}>
        <div
          className="triangle"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            this.handleTrendClick(e, data);
          }}
          onContextMenu={(e) => {
            this.handleTrendClick(e, data);
          }}
        ></div>
      </div>
    );
  }

  _rowClassName({ index }) {
    if (index < 0) {
      return "row-color-grey";
    } else {
      return index % 2 === 0 ? "row-color-grey" : "row-color-black";
    }
  }

  handleScroll = ({ target: { scrollTop } }) => {
    this.setState({ scrollTop });
  };

  isColumnHidden = (column) => {
    const { tableConfig } = this.props;
    for (let i = 0; i < tableConfig.length; i++) {
      if (tableConfig[i].column === column) {
        return tableConfig[i].hidden;
      }
    }
    return false;
  };

  getColumnPriority = (column) => {
    const { tableConfig } = this.props;
    let priority = 1;
    for (let i = 0; i < tableConfig.length; i++) {
      if (tableConfig[i].column === column) {
        return priority;
      }
      if (!tableConfig[i].hidden) {
        priority++;
      }
    }
    return -1;
  };

  handleMoneyFlowClick = async (e, row) => {
    e.preventDefault();

    const {
      discovery: { discoveryTimeframe },
      setMoneyFlowData,
      widget,
    } = this.props;
    const { symbol, moneyflow_dist } = row;

    const tf = TIMEFRAME_TRANSFORM_MAP[discoveryTimeframe[widget]];
    setMoneyFlowData({
      widget,
      symbol,
      dist: moneyflow_dist,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${MONEYFLOW_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getMoneyFlowData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setMoneyFlowData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setMoneyFlowData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleVolumeClick = async (e, row) => {
    e.preventDefault();

    const {
      discovery: { discoveryTimeframe },
      setVolumeData,
      widget,
    } = this.props;
    const { symbol, volume_dist } = row;

    const tf = TIMEFRAME_TRANSFORM_MAP[discoveryTimeframe[widget]];
    setVolumeData({
      widget,
      symbol,
      dist: volume_dist,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${VOLUME_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getVolumeData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setVolumeData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setVolumeData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleTradeCountClick = async (e, row) => {
    e.preventDefault();

    const {
      discovery: { discoveryTimeframe },
      setTradeCountData,
      widget,
    } = this.props;
    const { symbol } = row;

    const tf = TIMEFRAME_TRANSFORM_MAP[discoveryTimeframe[widget]];
    setTradeCountData({
      widget,
      symbol,
      loading: true,
      tf,
      data: [],
      err: "",
    });

    contextMenu.show({
      id: `${TRADE_COUNT_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });

    try {
      const data = await API.getTradeCountData(symbol, tf);
      if (!data || !Array.isArray(data) || data.length === 0) {
        throw "Empty data returned.";
      }
      setTradeCountData({
        widget,
        loading: false,
        data: data,
        err: "",
      });
    } catch (e) {
      console.log(e);
      setTradeCountData({
        widget,
        loading: false,
        data: [],
        err: "Data unavailable.",
      });
    }
  };

  handleTrendClick = async (e, row) => {
    e.preventDefault();

    const { setTrendData, widget } = this.props;
    const { symbol, trend } = row;

    setTrendData({
      widget,
      symbol,
      trend: trend[0],
      pricePoint: trend[1],
      ROI: 0,
    });

    contextMenu.show({
      id: `${TREND_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });
  };

  handleAlertClick = async (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const { widget } = this.props;

    contextMenu.show({
      id: `${ALERT_CONTEXT_MENU_ID}-${widget}`,
      event: e,
    });
    this.props.onAlertTrigger(widget, row.symbol, row.vWAPDist);
  };

  render() {
    const { scrollTop } = this.state;
    const {
      _sort,
      sortBy,
      sortDirection,
      discoveryData,
      tableConfig,
      isPro,
      isProOld,
      isProPlus,
      widget,
    } = this.props;
    let {
      config: { news: news_config, discovery: discovery_config },
    } = this.props;
    if (!news_config) {
      news_config = DEFAULT_NEWS_CONFIG;
    }
    if (!discovery_config) {
      discovery_config = DEFAULT_DISCOVERY_SETTING_LIST;
    }
    discovery_config = discovery_config.find((item) => item.id === widget);
    discovery_config = discovery_config?.value || DEFAULT_DISCOVERY_COLUMNS;

    let lastDistDisplayOption;
    let atrDistDisplayOption;
    let gapDistDisplayOption;
    for (const item of discovery_config) {
      if (item.column === "price_dist") {
        lastDistDisplayOption =
          item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
      }
      if (item.column === "atr") {
        atrDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
      }
      if (item.column === "gap") {
        gapDistDisplayOption = item.display || DISCOVERY_DISPLAY_PERCENT_CHANGE;
      }
    }

    return (
      <div
        className="container h-100 px-0 px-sm-3"
        style={{ position: "absolute" }}
      >
        {/* <ContextMenuTrigger
          id={"discovery-alert-context-menu"}
          ref={(c) => (alertContextTrigger = c)}
        >
        </ContextMenuTrigger> */}
        <InfiniteLoader
          isRowLoaded={({ index }) => !!discoveryData[index]}
          loadMoreRows={this.loadMore}
          rowCount={discoveryData.length}
        >
          {({ onRowsRendered }) => (
            <AutoSizer>
              {({ width, height }) => (
                <>
                  <Table
                    autoHeight
                    height={height}
                    sort={_sort}
                    headerHeight={50}
                    width={width}
                    style={{ fontSize: 14 }}
                    sortBy={sortBy}
                    rowCount={0}
                    sortDirection={sortDirection}
                  >
                    <Column
                      width={200}
                      label="Symbol"
                      dataKey="symbol"
                      style={{ fontWeight: 600, paddingLeft: 10 }}
                      headerClassName={"discovery-tbl-header"}
                    />
                    {this.isColumnHidden("price_dist") || (
                      <Column
                        width={200}
                        label="Last"
                        dataKey="price_dist"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("price_dist")}`}
                      />
                    )}
                    {this.isColumnHidden("volume") || (
                      <Column
                        width={200}
                        label="Volume"
                        dataKey="volume"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("volume")}`}
                      />
                    )}
                    {this.isColumnHidden("moneyflow") ||
                      !(isProPlus || isProOld) || (
                        <Column
                          width={200}
                          label="Money Flow"
                          dataKey="moneyflow"
                          headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("moneyflow")}`}
                        />
                      )}
                    {this.isColumnHidden("marketCap") || (
                      <Column
                        width={200}
                        label="Market Cap"
                        dataKey="marketCap"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("marketCap")}`}
                      />
                    )}
                    {this.isColumnHidden("float") || (
                      <Column
                        width={200}
                        label="Float"
                        dataKey="float"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("float")}`}
                      />
                    )}
                    {this.isColumnHidden("momentum") || (
                      <Column
                        width={200}
                        label="Momentum"
                        dataKey="momentum"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("momentum")}`}
                      />
                    )}
                    {this.isColumnHidden("tradeCount") || !isProPlus || (
                      <Column
                        width={200}
                        label="Trades"
                        dataKey="tradeCount"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("tradeCount")}`}
                      />
                    )}
                    {this.isColumnHidden("uVol") || (
                      <Column
                        width={200}
                        label="UVol"
                        dataKey="uVol"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("uVol")}`}
                      />
                    )}
                    {this.isColumnHidden("vWapDist") || (
                      <Column
                        width={200}
                        label="VWAPDist"
                        dataKey="vWAPDist"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("vWapDist")}`}
                      />
                    )}
                    {this.isColumnHidden("short_ratio") || !isProPlus || (
                      <Column
                        width={200}
                        label="Short Ratio"
                        dataKey="short_ratio"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("short_ratio")}`}
                      />
                    )}
                    {this.isColumnHidden("squeeze") ||
                      !(isProPlus || isProOld) || (
                        <Column
                          width={200}
                          label="Squeeze"
                          dataKey="squeeze"
                          disableSort
                          headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("squeeze")}`}
                          headerRenderer={({
                            columnData,
                            dataKey,
                            disableSort,
                            label,
                            sortBy,
                            sortDirection,
                          }) => {
                            const sort_direction_label =
                              SQUEEZE_SORT_LABEL[sortDirection];
                            return (
                              <span
                                className={`squeeze-header ${sortBy === dataKey ? "has-sort" : ""}`}
                                style={{}}
                                onClick={() => {
                                  _sort({ sortBy: dataKey });
                                }}
                              >
                                {
                                  <span
                                    className={`squeeze-label`}
                                    style={{}}
                                  ></span>
                                }
                                {sortBy === dataKey && (
                                  <span
                                    className={`squeeze-sort squeeze-sort-${sort_direction_label.toLowerCase()}`}
                                  >
                                    {sort_direction_label}
                                  </span>
                                )}
                              </span>
                            );
                          }}
                        />
                      )}
                    {this.isColumnHidden("atr") || !isProPlus || (
                      <Column
                        width={200}
                        label="ATR"
                        dataKey="atr"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("atr")} pl-2`}
                      />
                    )}
                    {this.isColumnHidden("halt") || (
                      <Column
                        width={200}
                        label="Halt"
                        dataKey="halt"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("halt")} pl-2`}
                      />
                    )}
                    {this.isColumnHidden("trend") || !isProPlus || (
                      <Column
                        width={200}
                        label="Trend"
                        dataKey="trend"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("trend")}`}
                      />
                    )}
                    {this.isColumnHidden("gap") || !isProPlus || (
                      <Column
                        width={200}
                        label="Gap"
                        dataKey="gap"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("gap")}`}
                      />
                    )}
                    {this.isColumnHidden("rs") || (
                      <Column
                        width={200}
                        label="RelStr"
                        dataKey="rs"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("rs")}`}
                      />
                    )}
                    {this.isColumnHidden("actions") || (
                      <Column
                        width={180}
                        dataKey="alert"
                        label="Actions"
                        headerClassName={`discovery-tbl-header column-priority-${this.getColumnPriority("actions")}`}
                        style={{ overflowX: "auto" }}
                      />
                    )}
                  </Table>
                  <Scrollbars
                    autoHide
                    style={{ height: height - 50, width: width }}
                    onScroll={this.handleScroll}
                    renderTrackVertical={(props) => (
                      <div className="track-vertical" {...props} />
                    )}
                  >
                    {/* <WindowScroller>
                    {({ height, isScrolling, onChildScroll }) => ( */}
                    <Table
                      autoHeight
                      height={height}
                      rowHeight={65}
                      sort={_sort}
                      headerHeight={0}
                      width={width}
                      style={{ fontSize: 14, height: "100%" }}
                      sortBy={sortBy}
                      onRowsRendered={(params) => {
                        onRowsRendered(params);
                        this.onViewportChanged(params);
                      }}
                      rowCount={discoveryData.length}
                      sortDirection={sortDirection}
                      rowGetter={({ index }) => discoveryData[index]}
                      rowClassName={(index) =>
                        index.index % 2 === 0 ? "oddRow" : "evenRow"
                      }
                      // isScrolling={isScrolling}
                      // onScroll={onChildScroll}
                      scrollTop={scrollTop}
                    >
                      <Column
                        width={200}
                        label="Symbol"
                        dataKey="symbol"
                        style={{ fontWeight: 600 }}
                        cellRenderer={({ cellData, rowData }) => {
                          let isNews = false;
                          if (!!rowData.news) {
                            const duration = moment.duration(
                              moment().diff(moment(rowData.news))
                            );
                            let diff_minutes = parseInt(duration.asMinutes());
                            if (diff_minutes <= 60 * news_config.recency) {
                              isNews = true;
                            }
                          }
                          return (
                            <div className="d-flex align-items-center">
                              <Symbol symbol={cellData} showOptions={true} />
                              <div className="d-flex flex-column">
                                {!rowData.trending || (
                                  <img
                                    className="stockwits"
                                    src={require("../../assets/images/dashboard/stock-tweets.svg")}
                                    style={{ position: "static" }}
                                  />
                                )}
                                {!isNews || (
                                  <img
                                    className="stockwits"
                                    src={require("../../assets/images/dashboard/news-icon-color.svg")}
                                    style={{
                                      position: "static",
                                      marginTop: rowData.trending ? "2px" : 0,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        }}
                      />
                      {this.isColumnHidden("price_dist") || (
                        <Column
                          width={200}
                          label="Last"
                          dataKey="price_dist"
                          className={`column-priority-${this.getColumnPriority("price_dist")}`}
                          cellRenderer={({ cellData, rowData }) => {
                            const distValue =
                              lastDistDisplayOption ===
                              DISCOVERY_DISPLAY_PERCENT_CHANGE
                                ? rowData.price_dist
                                : rowData.dollar_dist;
                            return (
                              <div>
                                <div style={{ fontWeight: 600 }}>
                                  {rowData.last.toFixed(2)}
                                </div>
                                <small
                                  className={
                                    "price-dist " +
                                    (distValue == 0
                                      ? ""
                                      : distValue > 0
                                        ? "text-success"
                                        : "text-danger")
                                  }
                                >
                                  {distValue > 0 ? "+" : ""}
                                  {distValue}
                                  {lastDistDisplayOption ===
                                  DISCOVERY_DISPLAY_PERCENT_CHANGE
                                    ? "%"
                                    : ""}
                                </small>
                              </div>
                            );
                          }}
                        />
                      )}
                      {this.isColumnHidden("volume") || (
                        <Column
                          width={200}
                          label="Volume"
                          dataKey="volume"
                          className={`column-volume column-priority-${this.getColumnPriority("volume")}`}
                          cellRenderer={({ cellData, rowData }) => (
                            <span
                              onClick={(e) => {
                                this.handleVolumeClick(e, rowData);
                              }}
                              onContextMenu={(e) => {
                                this.handleVolumeClick(e, rowData);
                              }}
                              style={{ color: "#9B9B9C", cursor: "pointer" }}
                            >
                              {!isNaN(cellData)
                                ? numAbbr.abbreviate(cellData, 2)
                                : "-"}
                            </span>
                          )}
                        />
                      )}
                      {this.isColumnHidden("moneyflow") ||
                        !(isProPlus || isProOld) || (
                          <Column
                            width={200}
                            label="Money Flow"
                            dataKey="moneyflow"
                            className={`column-moneyflow column-priority-${this.getColumnPriority("moneyflow")}`}
                            cellRenderer={({ cellData, rowData }) => (
                              <span
                                onClick={(e) => {
                                  this.handleMoneyFlowClick(e, rowData);
                                }}
                                onContextMenu={(e) => {
                                  this.handleMoneyFlowClick(e, rowData);
                                }}
                                style={{ color: "#9B9B9C", cursor: "pointer" }}
                              >
                                {!isNaN(cellData)
                                  ? numAbbrExt.abbreviate(cellData, 2)
                                  : cellData}
                              </span>
                            )}
                          />
                        )}
                      {this.isColumnHidden("marketCap") || (
                        <Column
                          width={200}
                          label="Market Cap"
                          dataKey="marketCap"
                          className={`column-priority-${this.getColumnPriority("marketCap")}`}
                          cellRenderer={({ cellData, rowData }) => (
                            <div style={{ color: "#9B9B9C" }}>
                              {cellData
                                ? numAbbrExt2.abbreviate(cellData, 2)
                                : "-"}
                            </div>
                          )}
                        />
                      )}
                      {this.isColumnHidden("float") || (
                        <Column
                          width={200}
                          label="Float"
                          dataKey="float"
                          className={`column-priority-${this.getColumnPriority("float")}`}
                          cellRenderer={({ cellData, rowData }) => (
                            <div style={{ color: "#9B9B9C" }}>
                              {cellData ? numAbbr.abbreviate(cellData, 2) : "-"}
                            </div>
                          )}
                        />
                      )}
                      {this.isColumnHidden("momentum") || (
                        <Column
                          width={200}
                          label="Momentum"
                          dataKey="momentum"
                          className={`column-momentum column-priority-${this.getColumnPriority("momentum")}`}
                          cellRenderer={({ cellData, rowData }) =>
                            this._renderCell(rowData.symbol, cellData, "", "")
                          }
                        />
                      )}
                      {this.isColumnHidden("tradeCount") || !isProPlus || (
                        <Column
                          width={200}
                          label="Trades"
                          dataKey="tradeCount"
                          className={`column-tradecount column-priority-${this.getColumnPriority("tradeCount")}`}
                          cellRenderer={({ cellData, rowData }) => (
                            <span
                              onClick={(e) => {
                                this.handleTradeCountClick(e, rowData);
                              }}
                              onContextMenu={(e) => {
                                this.handleTradeCountClick(e, rowData);
                              }}
                              style={{ color: "#9B9B9C", cursor: "pointer" }}
                            >
                              {!isNaN(cellData) && cellData > 0
                                ? numAbbr.abbreviate(cellData, 2)
                                : "-"}
                            </span>
                          )}
                        />
                      )}
                      {this.isColumnHidden("uVol") || (
                        <Column
                          width={200}
                          label="UVol"
                          dataKey="uVol"
                          className={`column-priority-${this.getColumnPriority("uVol")}`}
                          cellRenderer={({ cellData, rowData }) =>
                            this._renderCellUVol(
                              rowData.symbol,
                              cellData,
                              "%",
                              "0"
                            )
                          }
                        />
                      )}
                      {this.isColumnHidden("vWapDist") || (
                        <Column
                          width={200}
                          label="VWAPDist"
                          dataKey="vWAPDist"
                          className={`column-priority-${this.getColumnPriority("vWapDist")}`}
                          cellRenderer={({ cellData, rowData }) =>
                            this._renderCell(rowData.symbol, cellData, "%", "2")
                          }
                        />
                      )}
                      {this.isColumnHidden("short_ratio") || !isProPlus || (
                        <Column
                          width={200}
                          label="Short Ratio"
                          dataKey="short_ratio"
                          className={`column-short-ratio column-priority-${this.getColumnPriority("short_ratio")}`}
                          cellRenderer={
                            ({ cellData, rowData }) => (
                              <div style={{ color: "#9B9B9C" }}>
                                {cellData
                                  ? numAbbr.abbreviate(cellData, 2)
                                  : "-"}
                              </div>
                            )
                            // this._renderCell(rowData.symbol, cellData, "", "2")
                          }
                        />
                      )}
                      {this.isColumnHidden("squeeze") ||
                        !(isProPlus || isProOld) || (
                          <Column
                            width={200}
                            label="Squeeze"
                            dataKey="squeeze"
                            className={`column-squeeze column-priority-${this.getColumnPriority("squeeze")}`}
                            cellRenderer={({ cellData, rowData }) =>
                              this._renderCellSqueeze(rowData.symbol, cellData)
                            }
                          />
                        )}
                      {this.isColumnHidden("trend") || !isProPlus || (
                        <Column
                          width={200}
                          label="Trend"
                          dataKey="trend"
                          className={`column-priority-${this.getColumnPriority("trend")}`}
                          cellRenderer={({ cellData, rowData }) =>
                            this._renderCellTrend(rowData.symbol, rowData)
                          }
                        />
                      )}
                      {this.isColumnHidden("atr") || !isProPlus || (
                        <Column
                          width={200}
                          label="ATR"
                          dataKey="atr"
                          className={`column-priority-${this.getColumnPriority("atr")}`}
                          cellRenderer={({ cellData, rowData }) => {
                            const value =
                              atrDistDisplayOption ===
                              DISCOVERY_DISPLAY_PERCENT_CHANGE
                                ? rowData.atr
                                : rowData.atr_dollar_dist;
                            return (
                              <div style={{ color: "#9B9B9C" }}>
                                {value
                                  ? `${value}${atrDistDisplayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE ? "%" : ""}`
                                  : "-"}
                              </div>
                            );
                          }}
                        />
                      )}
                      {this.isColumnHidden("halt") || (
                        <Column
                          width={250}
                          label="Halt"
                          dataKey="halt"
                          className={`column-priority-${this.getColumnPriority("halt")}`}
                          cellRenderer={({ cellData, rowData }) => {
                            return (
                              <div
                                className="discovery-halt-luld"
                                style={{ color: "#9B9B9C" }}
                              >
                                {rowData["halt"] && (
                                  <i className="cell-halt fa fa-lock ml-3"></i>
                                )}
                                {!rowData["halt"] &&
                                  rowData["luld"] &&
                                  isRegularMarketOpen() && (
                                    <LULD
                                      up={rowData["luld"][0]}
                                      down={rowData["luld"][1]}
                                    />
                                  )}
                                {!rowData["halt"] &&
                                  (!rowData["luld"] ||
                                    !isRegularMarketOpen()) && (
                                    <span className="ml-3">-</span>
                                  )}
                              </div>
                            );
                          }}
                        />
                      )}
                      {this.isColumnHidden("gap") || !isProPlus || (
                        <Column
                          width={200}
                          label="GAP"
                          dataKey="gap"
                          className={`column-priority-${this.getColumnPriority("gap")}`}
                          cellRenderer={({ cellData, rowData }) => {
                            const value =
                              gapDistDisplayOption ===
                              DISCOVERY_DISPLAY_PERCENT_CHANGE
                                ? rowData.gap_percent_dist
                                : rowData.gap;
                            return (
                              <div style={{ color: "#9B9B9C" }}>
                                {value
                                  ? `${value}${gapDistDisplayOption === DISCOVERY_DISPLAY_PERCENT_CHANGE ? "%" : ""}`
                                  : "-"}
                              </div>
                            );
                          }}
                        />
                      )}
                      {this.isColumnHidden("rs") || (
                        <Column
                          width={200}
                          label="RelStr"
                          dataKey="rs"
                          className={`column-priority-${this.getColumnPriority("rs")}`}
                          cellRenderer={({ cellData, rowData }) =>
                            this._renderCell(rowData.symbol, cellData, "%", "1")
                          }
                        />
                      )}
                      {this.isColumnHidden("actions") || (
                        <Column
                          width={180}
                          dataKey="alert"
                          label="Actions"
                          className={`column-priority-${this.getColumnPriority("actions")}`}
                          style={{ overflowX: "auto" }}
                          cellRenderer={({ cellData, rowData }) => (
                            <div className="action-column">
                              <span
                                className="mdi mdi-bell text-white popover-icon action-button-margin"
                                onClick={(e) =>
                                  this.handleAlertClick(e, rowData)
                                }
                                onContextMenu={(e) =>
                                  this.handleAlertClick(e, rowData)
                                }
                              />
                              <i
                                className={`${
                                  this.props.checkIsFavorite(cellData)
                                    ? "mdi mdi-star quote-star popover-icon"
                                    : "mdi mdi-star text-white popover-icon"
                                }`}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  this.props.onSetSymbolFav(rowData.symbol)
                                }
                              />
                            </div>
                          )}
                        />
                      )}
                    </Table>
                    {/* )}
                  </WindowScroller> */}
                  </Scrollbars>
                </>
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setMoneyFlowData: DiscoveryActions.setMoneyFlowData,
  setVolumeData: DiscoveryActions.setVolumeData,
  setTradeCountData: DiscoveryActions.setTradeCountData,
  setTrendData: DiscoveryActions.setTrendData,
  setViewportSymbols: DiscoveryActions.setViewportSymbols,
};

const mapStateToProps = (state, props) => ({
  config: state.config,
  discovery: state.discovery,
  isPro:
    isActiveSubscription(state.auth.user.subscription) &&
    (isPro(state.auth.user.subscription.plan) ||
      isProNew(state.auth.user.subscription.plan)),
  isProOld:
    isActiveSubscription(state.auth.user.subscription) &&
    isPro(state.auth.user.subscription.plan),
  isProPlus:
    isActiveSubscription(state.auth.user.subscription) &&
    isProPlusNew(state.auth.user.subscription.plan),
});

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryTable);
