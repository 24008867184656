import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as firebase from "firebase/app";

import Dashboard from "./dashboard/Dashboard";
import DashboardFlow from "./dashboard/DashboardFlow";
import Settings from "./settings/Settings";
import UserAccount from "./user-pages/account";

import API from "./api";
import cogoToast from "cogo-toast";

import { getBrowserType } from "./shared/helper";

const REACT_APP_API = process.env.REACT_APP_API;

class AppRoutes extends Component {
  componentDidMount() {
    this.requestNotificationPermissions();
  }

  registerPushToken = async (registration_id, deviceType) => {
    const result = await API.registerPushToken(registration_id, deviceType);
    console.info("Push Token Register Result:", result);
  };

  requestNotificationPermissions = async () => {
    const browserType = getBrowserType();
    if (browserType.isSafari) {
      const checkRemotePermission = (permissionData) => {
        if (permissionData.permission === "default") {
          // This is a new web service URL and its validity is unknown.
        } else if (permissionData.permission === "denied") {
          // The user said no.
          console.log("SPN denied");
        } else if (permissionData.permission === "granted") {
          // The web service URL is a valid push provider, and the user said yes.
          // permissionData.deviceToken is now available to use.
          console.log("SPN granted");
          console.log(permissionData.deviceToken);
          this.registerPushToken(permissionData.deviceToken, "apn");
        }
      };

      var permissionData = window.safari.pushNotification.permission(
        "web.com.mometic.momo"
      );

      if (permissionData.permission === "default") {
        // This is a new web service URL and its validity is unknown.
        const { hide } = cogoToast.warn(
          <div>
            Enable push notification to get better experience!&nbsp;&nbsp;&nbsp;
            <span
              className="link-button"
              onClick={() => {
                hide();

                try {
                  window.safari.pushNotification.requestPermission(
                    `${REACT_APP_API}/api/spn`, // The web service URL.
                    "web.com.mometic.momo", // The Website Push ID.
                    {}, // Data that you choose to send to your server to help you identify the user.
                    checkRemotePermission // The callback function.
                  );
                } catch (error) {
                  console.log(error);
                  // setTimeout(() => {
                  //   cogoToast.error('Please allow the push notification permissions in the browser settings!')
                  // }, 500);
                }
              }}
            >
              Enable
            </span>
          </div>,
          {
            hideAfter: 0,
            onClick: async () => {
              hide();
            },
          }
        );
      } else if (permissionData.permission === "denied") {
        // cogoToast.error('Please allow the push notification permissions in the browser settings!')
      } else if (permissionData.permission === "granted") {
        console.log(permissionData.deviceToken);
        this.registerPushToken(permissionData.deviceToken, "apn");
      }
    } else {
      try {
        const registration_id = await firebase.messaging().getToken();
        if (registration_id) {
          this.registerPushToken(registration_id, "fcm");
        } else {
          // cogoToast.error('Please allow the push notification permissions in the browser settings!')
        }
      } catch (e) {
        // cogoToast.error('Please allow the push notification permissions in the browser settings!')
      }
    }
  };

  render() {
    return (
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/flow" component={DashboardFlow} />
        <Route exact path="/popout/:widget" component={Dashboard} />
        <Route exact path="/settings" component={Settings} />
        <Route exact path="/profile/:tab?" component={UserAccount} />
        <Redirect to="/dashboard" />
      </Switch>
    );
  }
}

export default AppRoutes;
