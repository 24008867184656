import styled, { css } from "styled-components";

const SymbolPopupContainer = styled.div`
  background-color: black;
  padding: 0px;
  z-index: 1000;
  box-shadow:
    0 4px 8px 0 rgb(68 68 68 / 20%),
    0 6px 20px 0 rgb(68 68 68 / 19%);
  border-radius: 4px;
  position: fixed;

  ${(props) => css`
    left: ${props.left}px;
    top: ${props.top}px;
    width: ${props.width}px;
  `}
`;

const SymbolIcon = styled.img`
  height: 44px;
  border-radius: 4px;
`;

const SymbolText = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: white;
`;

const SymbolName = styled.span`
  font-size: 12px;
  color: rgb(191, 191, 191);
`;

const SymbolItem = styled.div`
  background-color: black;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: ${({ mode }) => (mode === "context-menu" ? "6px 0" : "6px")};

  ${(props) =>
    props.hoverable &&
    css`
      &:hover {
        background-color: #505050;
        cursor: pointer;
      }
    `}

  ${SymbolIcon} {
    ${({ mode }) =>
      mode === "context-menu" &&
      css`
        height: 35px;
      `}
  }
`;

const SymbolInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  text-align: left;
  overflow: hidden;

  ${SymbolText}, ${SymbolName} {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export {
  SymbolPopupContainer,
  SymbolItem,
  SymbolIcon,
  SymbolInfoWrapper,
  SymbolText,
  SymbolName,
};
