import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import cogoToast from "cogo-toast";
import { withTranslation } from "react-i18next";

import "./App.scss";
import "./firebase"; // Init Firebase SDK
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import Login from "./user-pages/Login";
import Register from "./user-pages/Register";
import ForgotPassword from "./user-pages/ForgotPassword";
import ResetPassword from "./user-pages/ResetPassword";
import Verification from "./user-pages/Verification";
import SymbolPopup from "./shared/SymbolPopup";
import SearchDropdown from "./shared/SymbolSearchDropdown";
import Spinner from "../app/shared/Spinner";
import MenuItems from "./shared/MenuItems/MenuItems";
import Banner from "./banner/Banner";

import { store } from "./store/createStore";
import { AuthActions } from "./store";
import API from "./api";

import { isActiveSubscription, hasSubscription } from "./constants";

if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => {
    console.info("Firebase Notification Received:", payload);
    const message = payload.data.body;
    cogoToast.info(message);
    const audio = new Audio("https://momo.mometic.com/alert.mp3");
    audio.play();
  });
}

if (navigator.serviceWorker) {
  navigator.serviceWorker.onmessage = (event) => {
    if (event.data && event.data.type === "FCM_ALERT") {
      console.info("FCM event from Service Worker:", event);
      const message = event.data.body;
      cogoToast.info(message);
      const audio = new Audio("https://momo.mometic.com/alert.mp3");
      audio.play();
    }
  };
}

class App extends Component {
  onLogout = () => {
    store.dispatch({
      type: "USER_LOGOUT",
    });
  };

  render() {
    const { loading, authenticated } = this.props;
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/verify" component={Verification} />

        <ProtectedApp
          {...this.props}
          loading={loading}
          authenticated={authenticated}
          onLogout={this.onLogout}
        />
      </Switch>
    );
  }
}

class ProtectedApp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkEmailVerified();
  }

  checkEmailVerified = () => {
    API.signInWithToken()
      .then((data) => {
        this.props.setLoading(false);
        this.props.setUser(data.user);
        this.props.setAuthenticated(true);
      })
      .catch((error) => {
        this.props.setLoading(false);
        this.props.setUser({});
        this.props.setAuthenticated(false);
        console.info("sign in with token error", error);
      });
  };

  onLogout = () => {
    this.props.onLogout();
  };

  isVerified() {
    // email verified, subscribed
    const { user } = this.props;
    return user.email_verified && isActiveSubscription(user.subscription);
  }

  render() {
    // Check Auth
    if (this.props.loading) {
      return <Spinner />;
    } else if (!this.props.authenticated) {
      return <Redirect to="/login" />;
    }

    const { user, history, isDashPopout, isDashFlow } = this.props;

    let navbarComponent =
      !this.state.isFullPageLayout &&
      this.isVerified() &&
      !isDashPopout &&
      !isDashFlow ? (
        <Navbar onLogout={this.onLogout} />
      ) : null;
    let footerComponent =
      !this.state.isFullPageLayout &&
      this.isVerified() &&
      !isDashPopout &&
      !isDashFlow ? (
        <Footer />
      ) : null;

    if (!user.email_verified) {
      if (history.location.pathname !== "/verify") {
        return <Redirect to="/verify" />;
      }
    } else if (!isActiveSubscription(user.subscription)) {
      if (
        !["/profile", "/flow", "/popout/flow"].includes(
          history.location.pathname
        )
      ) {
        return <Redirect to="/profile" />;
      }
    }

    return (
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper">
          <Banner />
          {navbarComponent}
          <div
            className={
              navbarComponent
                ? "main-panel"
                : !isDashPopout && !isDashFlow
                  ? "main-panel pt-3"
                  : "main-panel pt-0"
            }
          >
            <div className="content-wrapper d-flex flex-column">
              <AppRoutes />
              <SymbolPopup />
              <SearchDropdown />
            </div>
            {footerComponent}
          </div>
        </div>
        <MenuItems
          menuIdKey={`discovery-context-menu`}
          data={["", "", "", "", "", ""]}
          type={""}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setAuthenticated: AuthActions.setAuthenticated,
  setLoading: AuthActions.setLoading,
  setUser: AuthActions.setUser,
};

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  loading: state.auth.loading,
  user: state.auth.user,
  isDashPopout: state.dashboard.isPagePopout,
  isDashFlow: state.dashboard.isPageFlow,
});

export default withTranslation()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
);
