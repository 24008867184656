import { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
  DEFAULT_DASHBOARD_MENU,
  DEFAULT_DISCOVERY_ALERT_POPUP,
  DEFAULT_DISCOVERY_COLUMNS,
  DEFAULT_DISCOVERY_FILTER,
  DEFAULT_DISCOVERY_SECTOR,
  DEFAULT_DISCOVERY_SETTING_LIST,
  DEFAULT_DISCOVERY_SORT,
  DEFAULT_HALT_CONFIG,
  DEFAULT_MONEYFLOW_DATA,
  DEFAULT_NEWS_CONFIG,
  DEFAULT_OPTIONS_MODE,
  DEFAULT_STREAM_SETTING,
  DEFAULT_STREAM_SETTING_LIST,
  DEFAULT_TREND_DATA,
  DEFAULT_VOICE_BUFFER,
  DEFAULT_VOLUME_DATA,
  STREAM_CHANNEL_MODE,
} from "./constants";

export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateUrl(url) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  );
}

export function delay(milisec) {
  return new Promise((resolve, reject) => {
    setTimeout(
      () => {
        resolve();
      },
      Math.max(milisec, 0)
    );
  });
}

export function isWebAppStandalone() {
  const STANDALONE = ":standalone:";
  const hash = window.location.hash;

  let standalone = false;

  if (hash === "#" + STANDALONE) {
    standalone = true;
    history.replaceState(history.state, "", "/");
  }

  if (matchMedia("(display-mode)").matches) {
    return matchMedia("(display-mode: standalone)").matches;
  }

  if (standalone) {
    sessionStorage.setItem(STANDALONE, "1");
  } else if (sessionStorage.getItem(STANDALONE)) {
    standalone = true;
  }

  return standalone;
}

export function getInteger(value) {
  if (isNaN(value)) {
    return "_";
  }
  let res = parseInt(value);
  if (res === 0 && value < 0) {
    res = "-" + res;
  }
  return res;
}

export function getDecimal(value, length = 1) {
  if (isNaN(value)) {
    return "_";
  }
  let decimal = Math.abs(value % 1);
  if (!decimal) {
    return "";
  }
  return (decimal + "000000000000").slice(1, 2 + length);
}

export function checkModified(a, b, ignorePaths) {
  const ignorePath = (path) => {
    return ignorePaths && ignorePaths.some((regex) => regex.test(path));
  };
  const isModified = (origin, data, path) => {
    const keys = Object.keys(data);

    for (let i = 0; i < keys.length; i++) {
      const childPath = `${path}.${keys[i]}`;
      if (!ignorePath(childPath)) {
        if (
          Object.prototype.toString.call(data[keys[i]]) == "[object Object]" ||
          Object.prototype.toString.call(data[keys[i]]) == "[object Array]"
        ) {
          if (!origin[keys[i]]) {
            return true;
          }

          if (isModified(origin[keys[i]], data[keys[i]], childPath)) {
            return true;
          }

          continue;
        }
        if (data[keys[i]] == null || data[keys[i]] == "") {
          if (origin[keys[i]] != null && origin[keys[i]] != "") {
            return true;
          }

          continue;
        }
        if (data[keys[i]] != origin[keys[i]]) {
          return true;
        }
      }
    }

    return false;
  };

  return isModified(a, b, "") || isModified(b, a, "");
}

export function useMediaQueryDetector(query) {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const matches = window.matchMedia(query);
    setMatch(matches.matches);

    matches.addEventListener("change", onChangeMatchMediaQuery);

    return () => matches.removeEventListener("change", onChangeMatchMediaQuery);
  }, [query]);

  const onChangeMatchMediaQuery = (e) => {
    setMatch(e.matches);
  };

  return [match];
}

export function getMarketStartEndTime(ESTTime, params = {}) {
  const { regularHours } = params;
  let marketStartTime = moment(new Date())
    .tz("America/New_York")
    .set("hour", 4)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  if (regularHours) {
    marketStartTime = moment(new Date())
      .tz("America/New_York")
      .set("hour", 9)
      .set("minute", 30)
      .set("second", 0)
      .set("millisecond", 0);
  }

  if (ESTTime.isAfter(marketStartTime)) {
    marketStartTime.add("day", 1);
  }
  if (marketStartTime.day() === 0) {
    marketStartTime.day(1);
  } else if (marketStartTime.day() === 6) {
    marketStartTime.day(1 + 7);
  }

  let marketEndTime = moment(marketStartTime)
    .tz("America/New_York")
    .set("hour", 20)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
  if (regularHours) {
    marketEndTime = moment(marketStartTime)
      .tz("America/New_York")
      .set("hour", 16)
      .set("minute", 0)
      .set("second", 0)
      .set("millisecond", 0);
  }

  marketEndTime.subtract("day", 1);
  if (marketEndTime.day() === 0) {
    marketEndTime.day(5 - 7);
  } else if (marketEndTime.day() === 6) {
    marketEndTime.day(5);
  }
  return {
    start: marketStartTime,
    end: marketEndTime,
  };
}

export function isMarketOpen() {
  const ESTTime = moment(new Date()).tz("America/New_York");
  const { start: marketStartTime, end: marketEndTime } =
    getMarketStartEndTime(ESTTime);
  return !ESTTime.isBetween(marketEndTime, marketStartTime);
}

export function isRegularMarketOpen() {
  const ESTTime = moment(new Date()).tz("America/New_York");
  const { start: marketStartTime, end: marketEndTime } = getMarketStartEndTime(
    ESTTime,
    { regularHours: true }
  );
  return !ESTTime.isBetween(marketEndTime, marketStartTime);
}

/**
 * functions for store data complement
 */
export function complementConfig(data) {
  let { discovery, stream, news, voiceNoti, voiceBuffer, optionsMode, halt } =
    data || {};
  if (!discovery || !Array.isArray(discovery)) {
    discovery = DEFAULT_DISCOVERY_SETTING_LIST;
  }
  if (!discovery[0].id) {
    discovery = [
      {
        ...DEFAULT_DISCOVERY_SETTING_LIST[0],
        value: discovery,
      },
      ...DEFAULT_DISCOVERY_SETTING_LIST.slice(1),
    ];
  }
  for (const item of discovery) {
    if (item.value.length < DEFAULT_DISCOVERY_COLUMNS.length) {
      const columnList = item.value.map(({ column }) => column);
      for (const column of DEFAULT_DISCOVERY_COLUMNS) {
        if (!columnList.includes(column.column)) {
          item.value.push({
            ...column,
          });
        }
      }
    }
  }

  if (!stream || !Array.isArray(stream)) {
    stream = DEFAULT_STREAM_SETTING_LIST;
  }
  for (const item of stream) {
    // if (!item.color) {
    //   item.color = '#c40000';
    // }
    if (!item.mode) {
      item.mode = STREAM_CHANNEL_MODE.DEFAULT;
    }
    if (!item.haltluld) {
      item.haltluld = { halt: true, luld: true };
    }
    if (item.channel === "main") {
      item.channel = "stream1";
      item.title = "Stream 1";
    }
    if (item.channel === "alt") {
      item.channel = "stream2";
      item.title = "Stream 2";
      item.color = "#eedb66";
    }
    if (!item.value) item.value = DEFAULT_STREAM_SETTING;
    if (!item.value.float) item.value.float = DEFAULT_STREAM_SETTING.float;
    if (!item.value.price) item.value.price = DEFAULT_STREAM_SETTING.price;
    if (!item.value.volume) item.value.volume = DEFAULT_STREAM_SETTING.volume;
    if (!item.value.count) item.value.count = DEFAULT_STREAM_SETTING.count;
    if (!item.value.atr) item.value.atr = DEFAULT_STREAM_SETTING.atr;
    if (!item.value.gap) item.value.gap = DEFAULT_STREAM_SETTING.gap;
    if (!item.value.marketCap)
      item.value.marketCap = DEFAULT_STREAM_SETTING.marketCap;
  }
  if (!news) {
    news = DEFAULT_NEWS_CONFIG;
  }
  if (!voiceNoti) {
    voiceNoti = [];
  }
  if (voiceBuffer == null) {
    voiceBuffer = DEFAULT_VOICE_BUFFER;
  }
  if (!halt) {
    halt = DEFAULT_HALT_CONFIG;
  }
  return {
    discovery,
    stream,
    news,
    voiceNoti,
    voiceBuffer,
    optionsMode: optionsMode || DEFAULT_OPTIONS_MODE,
    halt,
  };
}

export function complementLayout(data) {
  let layout = data;

  if (!layout || !Array.isArray(layout)) {
    layout = DEFAULT_DASHBOARD_LAYOUT_CONFIG;
  }

  for (let item of layout) {
    if (!item.menu) {
      item.menu = DEFAULT_DASHBOARD_MENU;
    }
    if (item.streamSelectedChannel) {
      item.menu.stream1 = false;
      item.menu.stream2 = false;
      if (item?.streamSelectedChannel?.stream1) {
        item.menu.stream1 = true;
      }
      if (item?.streamSelectedChannel?.stream2) {
        item.menu.stream1 = true;
        item.menu.stream2 = true;
      }
      delete item.menu.stream;
      delete item.streamSelectedChannel;
    }
    if (item.menu.hasOwnProperty("discovery")) {
      item.menu.discovery1 = false;
      item.menu.discovery2 = false;
      if (item.menu.discovery) {
        item.menu.discovery1 = true;
        item.layout.discovery1 = item.layout.discovery;
      }
      (item.popout || []).forEach((item) => {
        if (item.widget === "discovery") {
          item.widget = "discovery1";
        }
      });
      delete item.menu.discovery;
      delete item.layout.discovery;
    }
  }

  return layout;
}

export function complementDiscovery(data) {
  const discovery = { ...data };

  if (!discovery.discoverySector.discovery1) {
    discovery.discoverySector = {
      discovery1: discovery.discoverySector,
      discovery2: [DEFAULT_DISCOVERY_SECTOR],
    };
  }

  if (!discovery.moneyFlowData.discovery1) {
    discovery.moneyFlowData = {
      discovery1: discovery.moneyFlowData,
      discovery2: DEFAULT_MONEYFLOW_DATA,
    };
  }

  if (!discovery.volumeData.discovery1) {
    discovery.volumeData = {
      discovery1: discovery.volumeData,
      discovery2: DEFAULT_VOLUME_DATA,
    };
  }

  if (!discovery.trendData.discovery1) {
    discovery.trendData = {
      discovery1: discovery.trendData,
      discovery2: DEFAULT_TREND_DATA,
    };
  }

  if (typeof discovery.isFavFilter === "boolean") {
    discovery.isFavFilter = {
      discovery1: discovery.isFavFilter,
      discovery2: false,
    };
  }

  if (typeof discovery.selectedTableFilter !== "object") {
    discovery.selectedTableFilter = {
      discovery1: discovery.selectedTableFilter,
      discovery2: DEFAULT_DISCOVERY_FILTER,
    };
  }

  if (typeof discovery.discoverAlerySelected !== "object") {
    discovery.discoverAlerySelected = {
      discovery1: discovery.discoverAlerySelected,
      discovery2: DEFAULT_DISCOVERY_ALERT_POPUP,
    };
  }

  if (typeof discovery.discoveryTimeframe !== "object") {
    discovery.discoveryTimeframe = {
      discovery1: discovery.discoveryTimeframe,
      discovery2: "1day",
    };
  }

  if (!discovery.discoverySort.discovery1) {
    discovery.discoverySort = {
      discovery1: discovery.discoverySort,
      discovery2: DEFAULT_DISCOVERY_SORT,
    };
  }

  if (typeof discovery.discoveryFilter !== "object") {
    discovery.discoveryFilter = {
      discovery1: discovery.discoveryFilter,
      discovery2: "",
    };
  }

  if (typeof discovery.discoveryFilterExactMatch !== "object") {
    discovery.discoveryFilterExactMatch = {
      discovery1: discovery.discoveryFilterExactMatch,
      discovery2: false,
    };
  }

  if (typeof discovery.viewportSymbols !== "object") {
    discovery.viewportSymbols = {
      discovery1: [],
      discovery2: [],
    };
  }

  return discovery;
}

export function complementFlow(data) {
  let { filter, ...other } = data || {};
  if (!filter.hasOwnProperty("price") || filter.price < 0) {
    filter.price = 0;
  }
  return {
    ...other,
    filter,
  };
}

/**
 * add GTM analytics scripts
 */
export function addGTMAnalyticsScripts() {
  const headScriptId = "gtm-analytics-head";
  const bodyScriptId = "gtm-analytics-body";
  document.getElementById(headScriptId)?.remove();
  document.getElementById(bodyScriptId)?.remove();

  const s1 = document.createElement("script");
  s1.id = headScriptId;
  s1.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-5VSPCF');
  `;
  document.head.appendChild(s1);

  const s2 = document.createElement("noscript");
  s2.id = bodyScriptId;
  s2.innerHTML = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5VSPCF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;
  document.body.appendChild(s2);
}
