import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Scrollbars from "react-custom-scrollbars-2";
import { isMobile } from "react-device-detect";

import SymbolDetail from "../SymbolPopup/symbol-detail";
import SearchInput from "../SearchInput";

import { Types as DashboardActionTypes } from "../../dashboard/dashboardReducer";
import {
  SEARCH_DROPDOWN_MODE_SYMBOL,
  SEARCH_DROPDOWN_MODE_USER,
} from "../../constants";

const popupWidth = 250;
const MAX_SEARCH_RESULT_LIMIT = 50;

const SearchDropdownContainer = styled.div`
  background-color: black;
  padding: 4px;
  z-index: 9999;
  box-shadow:
    0 4px 8px 0 rgb(68 68 68 / 20%),
    0 6px 20px 0 rgb(68 68 68 / 19%);
  border-radius: 4px;
  position: fixed;
  ${({ top }) => top !== undefined && `top: ${top}px;`}
  ${({ right }) => right !== undefined && `right: ${right}px;`}
  ${({ bottom }) => bottom !== undefined && `bottom: ${bottom}px;`}
  ${({ left }) => left !== undefined && `left: ${left}px;`}
  ${({ width }) => width !== undefined && `width: ${width}px;`}
  ${({ height }) => height !== undefined && `height: ${height}px;`}
  overflow-y: auto;
`;

const DropdownUsernameItem = styled.div`
  background-color: black;
  padding: 4px 8px;
  font-size: 16px;
  &:hover {
    background-color: #505050;
    cursor: pointer;
  }
`;

const MobileSearchInput = styled(SearchInput)`
  position: fixed;
  top: 50px;
  right: 0;
  left: 0;
  border-radius: 0;
  border: 2px solid #333333;
  height: 42px;
  z-index: 9999;

  .search-bar {
    flex-grow: 1;
    height: 30px;
    font-size: 16px;
  }

  .search-icon-wrapper {
    height: 30px;
  }
`;

// const Backdrop = styled.div`
//   opacity: 0;
//   z-index: 999;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
// `

const SearchEmptyResult = styled.div`
  text-align: center;
  padding: 10px 5px;
  font-size: 16px;
  color: white;
`;

const SearchDropdown = function (props) {
  const dispatch = useDispatch();

  const {
    visible,
    mobileVisible,
    source,
    search,
    mobileSearch,
    mode,
    ...dropdownInfo
  } = useSelector((state) => state.dashboard.searchDropdown || {});
  const stocknames = useSelector((state) => state.stocknames.data || {});
  const usernames = useSelector((state) => state.misc.usernames || []);

  let isVisible =
    (!isMobile && visible && search) || (isMobile && mobileVisible);
  if (mode === SEARCH_DROPDOWN_MODE_USER && search?.length < 3) {
    isVisible = false;
  }

  const _refSearchInput = useRef(null);
  const [matches, setMatches] = useState([]);

  useEffect(() => {
    let res = [];

    const searchValue = (search || "").toUpperCase();

    if (search) {
      if (mode === SEARCH_DROPDOWN_MODE_SYMBOL) {
        for (const key in stocknames) {
          const { s, n } = stocknames[key];
          if (
            (s || "").toUpperCase().includes(searchValue) ||
            (n || "").toUpperCase().includes(searchValue)
          ) {
            res.push({
              id: s,
              value: s,
            });
          }
        }
      }
      if (mode === SEARCH_DROPDOWN_MODE_USER) {
        res = usernames
          .filter((item) => item.username.toUpperCase().includes(searchValue))
          .map(({ id, username }) => ({
            id,
            value: username,
          }));
      }
    }

    res.sort((a, b) => {
      const aval = (a.value || "").toUpperCase();
      const bval = (b.value || "").toUpperCase();
      if (aval === searchValue) return -1;
      if (bval === searchValue) return 1;
      if (aval < bval) return -1;
      else if (aval > bval) return 1;
      else return 0;
    });

    setMatches(res.slice(0, MAX_SEARCH_RESULT_LIMIT));
  }, [search, stocknames, usernames, mode]);

  useEffect(() => {
    if (isMobile && mobileVisible) {
      _refSearchInput.current && _refSearchInput.current.focus();
    }
  }, [isMobile, mobileVisible]);

  useEffect(() => {
    const keyPressHandler = (ev) => {
      if (isVisible && ev.key === "Enter" && matches.length > 0) {
        onClickItem(null, matches[0]);
      }
    };
    document.addEventListener("keypress", keyPressHandler);
    return () => {
      document.removeEventListener("keypress", keyPressHandler);
    };
  }, [isVisible, matches]);

  const onChangeSearch = (text, boundingRect) => {
    const value = text;
    let dropdownSearchText = value;
    let dropdownMode = mode;

    if (source === "chat") {
      if (value.startsWith("@")) {
        dropdownMode = SEARCH_DROPDOWN_MODE_USER;
        dropdownSearchText = (dropdownSearchText || "").slice(1);
      } else {
        dropdownMode = SEARCH_DROPDOWN_MODE_SYMBOL;
      }
    }

    dispatch({
      type: DashboardActionTypes.UPDATE_SEARCH_DROPDOWN,
      value: {
        visible: true,
        mobileVisible: true,
        mode: dropdownMode,
        mobileSearch: value,
        search: dropdownSearchText,
      },
    });
  };

  const onClearSearch = (e) => {
    dispatch({
      type: DashboardActionTypes.UPDATE_SEARCH_DROPDOWN,
      value: {
        mode: "",
        visible: false,
        mobileVisible: false,
        mobileSearch: "",
        search: "",
        source: null,
      },
    });
  };

  const onClickItem = (e, item) => {
    dispatch({
      type: DashboardActionTypes.UPDATE_SEARCH_DROPDOWN_CLICKED,
      value: item.value,
      extra: {
        id: item.id,
      },
    });
    // if (isMobile) {
    setTimeout(() => {
      dispatch({
        type: DashboardActionTypes.UPDATE_SEARCH_DROPDOWN,
        value: {
          mode: "",
          visible: false,
          mobileVisible: false,
          mobileSearch: "",
          search: "",
          source: null,
        },
      });
    }, 200);
    // }
  };

  const getPanelHeight = () => {
    let height = 0;
    let itemHeight = 0;

    if (mode === SEARCH_DROPDOWN_MODE_SYMBOL) {
      itemHeight = 56;
    } else if (mode === SEARCH_DROPDOWN_MODE_USER) {
      itemHeight = 32;
    }

    if (isVisible) {
      height = matches.length
        ? Math.min(288, itemHeight * matches.length + 10)
        : 56;
    }
    return height;
  };

  const getSize = () => {
    if (isMobile) {
      return {
        top: 50 + 41,
        right: 0,
        bottom: 0,
        left: 0,
      };
    }

    const scrollbarWidth = 25; // Don't need to be precise
    const closeBtnWidth = 26;
    let right =
      window.innerWidth - dropdownInfo.right - scrollbarWidth - closeBtnWidth;
    let top = dropdownInfo.bottom + 2;
    const popupHeight = getPanelHeight();
    if (top > window.innerHeight - popupHeight) {
      top = dropdownInfo.top - popupHeight;
    }

    return {
      top,
      right,
      width: popupWidth,
      height: popupHeight,
    };
  };

  if (
    mode !== SEARCH_DROPDOWN_MODE_SYMBOL &&
    mode !== SEARCH_DROPDOWN_MODE_USER
  ) {
    return null;
  }

  return isVisible ? (
    <>
      {isMobile && (
        <MobileSearchInput
          ref={_refSearchInput}
          className={""}
          value={mobileSearch}
          placeholder={source === "chat" ? "User or Symbol..." : "Symbol..."}
          onChange={onChangeSearch}
          onClear={onClearSearch}
          closeVisible={true}
        />
      )}
      <SearchDropdownContainer {...getSize()}>
        <Scrollbars
          autoHide
          style={{
            width: "100%",
            height: "100%",
          }}
          renderTrackVertical={(props) => (
            <div className="track-vertical" {...props} />
          )}
        >
          {mode === SEARCH_DROPDOWN_MODE_SYMBOL &&
            matches.map((item) => (
              <SymbolDetail
                key={item.id}
                symbol={item.value}
                hoverable={true}
                onClick={(e) => onClickItem(e, item)}
              />
            ))}
          {mode === SEARCH_DROPDOWN_MODE_USER &&
            matches.map((item) => (
              <DropdownUsernameItem
                key={item.id}
                onClick={(e) => onClickItem(e, item)}
              >
                {item.value}
              </DropdownUsernameItem>
            ))}
          {search && !matches.length && (
            <SearchEmptyResult>NO RESULTS FOUND</SearchEmptyResult>
          )}
        </Scrollbars>
      </SearchDropdownContainer>
      {/* <Backdrop
        onClick={(e) => onClose && onClose(e)}
      /> */}
    </>
  ) : null;
};

// SearchDropdown.propTypes = {
//   search: PropTypes.string.isRequired,
//   visible: PropTypes.bool.isRequired,
//   onClick: PropTypes.func,
//   onClose: PropTypes.func
// }

export default SearchDropdown;
