import { createActions, createReducer } from "reduxsauce";
import randomstring from "randomstring";
import {
  DEFAULT_DISCOVERY_ALERT_POPUP,
  DEFAULT_DISCOVERY_FILTER,
  DEFAULT_DISCOVERY_SECTOR,
  DEFAULT_DISCOVERY_SORT,
  DEFAULT_MONEYFLOW_DATA,
  DEFAULT_TRADECOUNT_DATA,
  DEFAULT_TREND_DATA,
  DEFAULT_VOLUME_DATA,
  DISCOVERY_FILTER_TEMP_PREFIX,
} from "../constants";

export const FILTER_TYPE = {
  ALL: "ALL",
  ANY: "ANY",
};

export const defaultTableFilter = {
  type: FILTER_TYPE.ALL,
  values: {},
};

const defaultState = {
  discoveryFilter: {
    discovery1: "",
    discovery2: "",
  },
  discoveryFilterExactMatch: {
    discovery1: false,
    discovery2: false,
  },
  discoveryIndex: 50,
  discoverySort: {
    discovery1: DEFAULT_DISCOVERY_SORT,
    discovery2: DEFAULT_DISCOVERY_SORT,
  },
  discoverySector: {
    discovery1: [DEFAULT_DISCOVERY_SECTOR],
    discovery2: [DEFAULT_DISCOVERY_SECTOR],
  },
  discoveryTimeframe: {
    discovery1: "1day",
    discovery2: "1day",
  },
  discoverAlerySelected: {
    discovery1: DEFAULT_DISCOVERY_ALERT_POPUP,
    discovery2: DEFAULT_DISCOVERY_ALERT_POPUP,
  },
  tableFilters: {
    [DEFAULT_DISCOVERY_FILTER]: {
      ...defaultTableFilter,
    },
  },
  selectedTableFilter: {
    discovery1: DEFAULT_DISCOVERY_FILTER,
    discovery2: DEFAULT_DISCOVERY_FILTER,
  },
  error: "",
  isFavFilter: {
    discovery1: false,
    discovery2: false,
  },
  moneyFlowData: {
    discovery1: DEFAULT_MONEYFLOW_DATA,
    discovery2: DEFAULT_MONEYFLOW_DATA,
  },
  volumeData: {
    discovery1: DEFAULT_VOLUME_DATA,
    discovery2: DEFAULT_VOLUME_DATA,
  },
  tradeCountData: {
    discovery1: DEFAULT_TRADECOUNT_DATA,
    discovery2: DEFAULT_TRADECOUNT_DATA,
  },
  trendData: {
    discovery1: DEFAULT_TREND_DATA,
    discovery2: DEFAULT_TREND_DATA,
  },
  viewportSymbols: {
    discovery1: [],
    discovery2: [],
  },
};

export const { Types, Creators } = createActions({
  statsFetchSucceed: ["stats"],
  statsFetchFailed: [],
  alertSelected: ["widget", "symbol", "vWAPDist"],
  updateDiscoveryIndex: ["index"],
  updateDiscoveryFilter: ["value", "widget"],
  updateDiscoverySector: ["value"],
  resetDiscoverySector: ["widget"],
  updateDiscoveryTimeframe: ["value"],
  updateDiscoverySort: ["value", "widget"],
  toggleFavFilter: ["widget"],
  updateTableFilter: ["payload"],
  removeTableFilter: ["filterName"],
  addTableFilter: ["payload"],
  setDiscovery: ["discovery"],
  setMoneyFlowData: ["value"],
  setVolumeData: ["value"],
  setTradeCountData: ["value"],
  setTrendData: ["value"],
  setViewportSymbols: ["value"],
});

const removeTableFilter = (state, { filterName }) => {
  const tableFilters = { ...state.tableFilters };
  delete tableFilters[filterName];

  const selectedTableFilter = {
    ...state.selectedTableFilter,
  };
  for (const widget in selectedTableFilter) {
    if (selectedTableFilter[widget] === filterName) {
      selectedTableFilter[widget] = DEFAULT_DISCOVERY_FILTER;
    }
  }
  return {
    ...state,
    selectedTableFilter,
    tableFilters,
  };
};

const addTableFilter = (state, { payload }) => {
  const { widget, filter } = payload;
  const tableFilters = { ...state.tableFilters };
  const filterName =
    filter.name || `${DISCOVERY_FILTER_TEMP_PREFIX}${randomstring.generate(5)}`;
  tableFilters[filterName] = {
    type: filter.type,
    color: filter.color,
    values: filter.values,
  };
  return {
    ...state,
    selectedTableFilter: {
      ...state.selectedTableFilter,
      [widget]: filterName,
    },
    tableFilters,
  };
};

const updateTableFilter = (state, { payload: { widget, filterName } }) => ({
  ...state,
  selectedTableFilter: {
    ...state.selectedTableFilter,
    [widget]: filterName,
  },
});

const toggleFavFilter = (state, { widget }) => ({
  ...state,
  isFavFilter: {
    ...state.isFavFilter,
    [widget]: !state.isFavFilter[widget],
  },
});

const updateDiscoverySector = (state, { value }) => {
  const { sector, widget } = value;
  let discoverySector = [...(state.discoverySector[widget] || [])];
  if (discoverySector.includes(sector)) {
    discoverySector = discoverySector.filter((item) => item !== sector);
  } else {
    if (discoverySector.includes(DEFAULT_DISCOVERY_SECTOR)) {
      discoverySector = [];
    }
    discoverySector.push(sector);
  }
  if (
    discoverySector.includes(DEFAULT_DISCOVERY_SECTOR) ||
    discoverySector.length === 0
  )
    discoverySector = [DEFAULT_DISCOVERY_SECTOR];
  return {
    ...state,
    discoverySector: {
      ...state.discoverySector,
      [widget]: discoverySector,
    },
  };
};

const resetDiscoverySector = (state, { widget }) => {
  return {
    ...state,
    discoverySector: {
      ...state.discoverySector,
      [widget]: [DEFAULT_DISCOVERY_SECTOR],
    },
  };
};

const updateDiscoveryTimeframe = (state, { value }) => {
  const { widget, timeframe } = value;
  return {
    ...state,
    discoveryTimeframe: {
      ...state.discoveryTimeframe,
      [widget]: timeframe,
    },
  };
};

const updateDiscoveryFilter = (state, { value, widget }) => {
  const { symbol, exactMatch = false } = value;
  return {
    ...state,
    discoveryFilter: {
      ...state.discoveryFilter,
      [widget]: symbol,
    },
    discoveryFilterExactMatch: {
      ...state.discoveryFilterExactMatch,
      [widget]: exactMatch,
    },
  };
};

const updateDiscoverySort = (state, { value, widget }) => {
  return {
    ...state,
    discoverySort: {
      ...state.discoverySort,
      [widget]: value,
    },
  };
};

const updateDiscoveryIndex = (state, { index }) => {
  return {
    ...state,
    discoveryIndex: index,
  };
};

const alertSelected = (state, { widget, symbol, vWAPDist }) => {
  return {
    ...state,
    discoverAlerySelected: {
      ...state.discoverAlerySelected,
      [widget]: {
        symbol,
        vWAPDist,
      },
    },
  };
};

const setDiscovery = (state, { discovery }) => {
  return {
    ...state,
    ...discovery,
  };
};

const setMoneyFlowData = (state, { value }) => {
  const { widget, symbol, dist, loading, tf, data, err } = value;
  const moneyFlowData = (state.moneyFlowData || {})[widget] || {};
  if (symbol) moneyFlowData["symbol"] = symbol;
  if (dist !== undefined && dist !== null) moneyFlowData["dist"] = dist;
  if (loading !== undefined && loading !== null)
    moneyFlowData["loading"] = loading;
  if (tf) moneyFlowData["tf"] = tf;
  if (data) moneyFlowData["data"] = [...data];
  if (err !== undefined && err !== null) moneyFlowData["err"] = err;
  return {
    ...state,
    moneyFlowData: {
      ...state.moneyFlowData,
      [widget]: moneyFlowData,
    },
  };
};

const setVolumeData = (state, { value }) => {
  const { widget, symbol, dist, loading, tf, data, err } = value;
  const volumeData = (state.volumeData || {})[widget] || {};
  if (symbol) volumeData["symbol"] = symbol;
  if (dist !== undefined && dist !== null) volumeData["dist"] = dist;
  if (loading !== undefined && loading !== null)
    volumeData["loading"] = loading;
  if (tf) volumeData["tf"] = tf;
  if (data) volumeData["data"] = [...data];
  if (err !== undefined && err !== null) volumeData["err"] = err;
  return {
    ...state,
    volumeData: {
      ...state.volumeData,
      [widget]: volumeData,
    },
  };
};

const setTradeCountData = (state, { value }) => {
  const { widget, symbol, loading, tf, data, err } = value;
  const tradeCountData = (state.tradeCountData || {})[widget] || {};
  if (symbol) tradeCountData["symbol"] = symbol;
  if (loading !== undefined && loading !== null)
    tradeCountData["loading"] = loading;
  if (tf) tradeCountData["tf"] = tf;
  if (data) tradeCountData["data"] = [...data];
  if (err !== undefined && err !== null) tradeCountData["err"] = err;
  return {
    ...state,
    tradeCountData: {
      ...state.tradeCountData,
      [widget]: tradeCountData,
    },
  };
};

const setTrendData = (state, { value }) => {
  const { widget, symbol, trend, pricePoint, ROI } = value;
  return {
    ...state,
    trendData: {
      ...state.trendData,
      [widget]: {
        symbol,
        trend,
        pricePoint,
        ROI,
      },
    },
  };
};

const setViewportSymbols = (state, { value }) => {
  const { widget, symbols } = value;
  return {
    ...state,
    viewportSymbols: {
      ...state.viewportSymbols,
      [widget]: symbols,
    },
  };
};

export const DiscoveryTypes = Types;

export const discoveryReducer = createReducer(defaultState, {
  [Types.ALERT_SELECTED]: alertSelected,
  [Types.UPDATE_DISCOVERY_INDEX]: updateDiscoveryIndex,
  [Types.UPDATE_DISCOVERY_FILTER]: updateDiscoveryFilter,
  [Types.UPDATE_DISCOVERY_SECTOR]: updateDiscoverySector,
  [Types.RESET_DISCOVERY_SECTOR]: resetDiscoverySector,
  [Types.UPDATE_DISCOVERY_TIMEFRAME]: updateDiscoveryTimeframe,
  [Types.UPDATE_DISCOVERY_SORT]: updateDiscoverySort,
  [Types.TOGGLE_FAV_FILTER]: toggleFavFilter,
  [Types.UPDATE_TABLE_FILTER]: updateTableFilter,
  [Types.REMOVE_TABLE_FILTER]: removeTableFilter,
  [Types.ADD_TABLE_FILTER]: addTableFilter,
  [Types.SET_DISCOVERY]: setDiscovery,
  [Types.SET_MONEY_FLOW_DATA]: setMoneyFlowData,
  [Types.SET_VOLUME_DATA]: setVolumeData,
  [Types.SET_TRADE_COUNT_DATA]: setTradeCountData,
  [Types.SET_TREND_DATA]: setTrendData,
  [Types.SET_VIEWPORT_SYMBOLS]: setViewportSymbols,
});

export default Creators;
