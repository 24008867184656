import { createActions, createReducer } from "reduxsauce";
import {
  DEFAULT_DASHBOARD_LAYOUT,
  DEFAULT_DASHBOARD_LAYOUT_CONFIG,
  DEFAULT_SEARCH_DROPDOWN,
  DEFAULT_SYMBOL_POPUP,
  DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM,
  DEFAULT_DASHBOARD_MENU,
} from "../constants";
import { complementLayout } from "../util";

const defaultState = {
  showSpinner: false,

  contextMenuSymbol: null,

  maximisedView: "",
  layout: DEFAULT_DASHBOARD_LAYOUT_CONFIG,
  layoutDeviceMedia: null,
  layoutRestored: false,
  layoutForceUpdate: 0,
  isPagePopout: false,
  isPopoutBlocked: false,
  isPageFlow: false,

  windowPlacementPermissionRequested: false,

  alerts: [],
  alertDetailItem: null,
  popularSymbols: [],
  symbolPopup: DEFAULT_SYMBOL_POPUP,
  searchDropdown: DEFAULT_SEARCH_DROPDOWN,
};

export const { Types, Creators } = createActions({
  maximiseView: ["view"],
  updateContextMenuSymbol: ["symbol"],
  showSpinner: [],
  hideSpinner: [],
  setPopularSymbols: ["value"],
  setAlerts: ["value"],
  addAlerts: ["value"],
  setAlertDetailItem: ["value"],
  updateSymbolPopup: ["value"],
  updateSearchDropdown: ["value"],
  updateSearchDropdownClicked: ["value", "extra"],
  resetLayout: ["value"],
  setLayoutConfig: ["value"],
  setLayoutActive: ["value"],
  updateActiveLayoutConfig: ["value"],
  toggleLayoutLock: ["value"],
  toggleLayoutWidget: ["value"],
  resetLayoutWidget: [],
  openLayoutWidgetPopout: ["value"],
  closeLayoutWidgetPopout: ["value"],
  updateLayoutWidgetScreenInfo: ["value"],
  updateLayoutDeviceMedia: ["value"],
  triggerLayoutForceUpdate: [],
  setIsPagePopout: ["value"],
  setIsPopoutBlocked: ["value"],
  setIsPageFlow: ["value"],
  updateWindowPlacementPermissionRequested: ["value"],
});

const showSpinner = (state) => ({
  ...state,
  showSpinner: true,
});

const hideSpinner = (state) => ({
  ...state,
  showSpinner: false,
});

const maximiseView = (state, { view }) => ({
  ...state,
  maximisedView: view,
});

const updateContextMenuSymbol = (state, { symbol }) => ({
  ...state,
  contextMenuSymbol: symbol,
});

const setPopularSymbols = (state, { value }) => ({
  ...state,
  popularSymbols: value || [],
});

const setAlerts = (state, { value }) => ({
  ...state,
  alerts: value || [],
});

const addAlerts = (state, { value }) => ({
  ...state,
  alerts: [...value, ...state.alerts],
});

const setAlertDetailItem = (state, { value }) => ({
  ...state,
  alertDetailItem: value,
});

const updateSymbolPopup = (state, { value }) => {
  return {
    ...state,
    symbolPopup: {
      ...(state.symbolPopup || {}),
      ...value,
    },
  };
};

const updateSearchDropdown = (state, { value }) => {
  return {
    ...state,
    searchDropdown: {
      ...(state.searchDropdown || {}),
      ...value,
      clicked: {
        symbol: "",
        source: "",
        extra: null,
      },
    },
  };
};

const updateSearchDropdownClicked = (state, { value, extra }) => {
  const clickedSource = value ? state?.searchDropdown?.source : "";
  return {
    ...state,
    searchDropdown: {
      ...state.searchDropdown,
      clicked: {
        symbol: value,
        source: clickedSource,
        extra,
      },
    },
  };
};

const resetLayout = (state, { value }) => {
  return {
    ...state,
    maximisedView: "",
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active
          ? {
              ...item,
              layout: value || DEFAULT_DASHBOARD_LAYOUT,
              menu: DEFAULT_DASHBOARD_MENU,
              popout: [],
            }
          : item
      ),
    ],
  };
};

const setLayoutConfig = (state, { value }) => {
  const { layout, layoutDeviceMedia } = value;
  // keep active/inactive information
  if (Array.isArray(state.layout)) {
    const activeInfo = {};
    for (const item of state.layout) {
      activeInfo[item.key] = item.active;
    }
    Array.isArray(layout) &&
      layout.map((item) => (item.active = activeInfo[item.key]));
  }
  return {
    ...state,
    layout: complementLayout(layout),
    layoutDeviceMedia,
    layoutRestored: true,
  };
};

const setLayoutActive = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => ({
        ...item,
        active: item.key === value,
      })),
    ],
  };
};

const updateActiveLayoutConfig = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) =>
        item.active
          ? {
              ...item,
              layout: value,
            }
          : item
      ),
    ],
  };
};

const toggleLayoutLock = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => ({
        ...item,
        locked: item.key === value ? !item.locked : item.locked,
      })),
    ],
  };
};

const toggleLayoutWidget = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          return {
            ...item,
            menu: {
              ...item.menu,
              [value]: !item.menu[value],
            },
          };
        } else {
          return item;
        }
      }),
    ],
  };
};

const resetLayoutWidget = (state, {}) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          return {
            ...item,
            menu: DEFAULT_DASHBOARD_MENU,
          };
        } else {
          return item;
        }
      }),
    ],
  };
};

const openLayoutWidgetPopout = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          const popout = [...(item.popout || [])];
          if (!popout.find((item) => item.widget === value)) {
            popout.push({
              widget: value,
              screen: null,
            });
          }
          return {
            ...item,
            popout,
          };
        } else {
          return item;
        }
      }),
    ],
  };
};

const closeLayoutWidgetPopout = (state, { value }) => {
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          return {
            ...item,
            popout: [
              ...(item.popout || []).filter((item) => item.widget !== value),
            ],
          };
        } else {
          return item;
        }
      }),
    ],
  };
};

const updateLayoutWidgetScreenInfo = (state, { value }) => {
  const { widget, screen } = value;
  return {
    ...state,
    layout: [
      ...(state.layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).map((item) => {
        if (item.active) {
          return {
            ...item,
            popout: [
              ...(item.popout || []).map((item1) => {
                if (item1.widget !== widget) return item1;
                else
                  return {
                    widget,
                    screen,
                  };
              }),
            ],
          };
        } else {
          return item;
        }
      }),
    ],
  };
};

const updateLayoutDeviceMedia = (state, { value }) => {
  return {
    ...state,
    layoutDeviceMedia: value,
  };
};

const triggerLayoutForceUpdate = (state) => {
  return {
    ...state,
    layoutForceUpdate: (state.layoutForceUpdate || 0) + 1,
  };
};

const setIsPagePopout = (state, { value }) => {
  return {
    ...state,
    isPagePopout: value,
  };
};

const setIsPageFlow = (state, { value }) => {
  return {
    ...state,
    isPageFlow: value,
  };
};

const setIsPopoutBlocked = (state, { value }) => {
  return {
    ...state,
    isPopoutBlocked: value,
  };
};

const updateWindowPlacementPermissionRequested = (state, { value }) => {
  return {
    ...state,
    windowPlacementPermissionRequested: !!value,
  };
};

export const DashboardTypes = Types;

export const dashboardReducer = createReducer(defaultState, {
  [Types.MAXIMISE_VIEW]: maximiseView,
  [Types.UPDATE_CONTEXT_MENU_SYMBOL]: updateContextMenuSymbol,
  [Types.SHOW_SPINNER]: showSpinner,
  [Types.HIDE_SPINNER]: hideSpinner,
  [Types.SET_POPULAR_SYMBOLS]: setPopularSymbols,
  [Types.SET_ALERTS]: setAlerts,
  [Types.ADD_ALERTS]: addAlerts,
  [Types.SET_ALERT_DETAIL_ITEM]: setAlertDetailItem,
  [Types.UPDATE_SYMBOL_POPUP]: updateSymbolPopup,
  [Types.UPDATE_SEARCH_DROPDOWN]: updateSearchDropdown,
  [Types.UPDATE_SEARCH_DROPDOWN_CLICKED]: updateSearchDropdownClicked,
  [Types.RESET_LAYOUT]: resetLayout,
  [Types.SET_LAYOUT_CONFIG]: setLayoutConfig,
  [Types.SET_LAYOUT_ACTIVE]: setLayoutActive,
  [Types.UPDATE_ACTIVE_LAYOUT_CONFIG]: updateActiveLayoutConfig,
  [Types.TOGGLE_LAYOUT_LOCK]: toggleLayoutLock,
  [Types.TOGGLE_LAYOUT_WIDGET]: toggleLayoutWidget,
  [Types.RESET_LAYOUT_WIDGET]: resetLayoutWidget,
  [Types.OPEN_LAYOUT_WIDGET_POPOUT]: openLayoutWidgetPopout,
  [Types.CLOSE_LAYOUT_WIDGET_POPOUT]: closeLayoutWidgetPopout,
  [Types.UPDATE_LAYOUT_WIDGET_SCREEN_INFO]: updateLayoutWidgetScreenInfo,
  [Types.UPDATE_LAYOUT_DEVICE_MEDIA]: updateLayoutDeviceMedia,
  [Types.TRIGGER_LAYOUT_FORCE_UPDATE]: triggerLayoutForceUpdate,
  [Types.SET_IS_PAGE_POPOUT]: setIsPagePopout,
  [Types.SET_IS_POPOUT_BLOCKED]: setIsPopoutBlocked,
  [Types.SET_IS_PAGE_FLOW]: setIsPageFlow,
  [Types.UPDATE_WINDOW_PLACEMENT_PERMISSION_REQUESTED]:
    updateWindowPlacementPermissionRequested,
});

export default Creators;

/**
 * Utility functions
 */
export const getActiveLayout = (layout, setLayoutActive) => {
  let res;
  const activeLayout = (layout || DEFAULT_DASHBOARD_LAYOUT_CONFIG).filter(
    (item) => item && item.active
  );
  if (!activeLayout.length) {
    res = DEFAULT_DASHBOARD_LAYOUT_CONFIG[0];
    setLayoutActive && setLayoutActive(res.key);
  } else if (activeLayout.length > 1) {
    res = activeLayout[0];
    setLayoutActive && setLayoutActive(res.key);
  } else {
    res = activeLayout[0];
  }
  res.layout = res.layout || DEFAULT_DASHBOARD_LAYOUT;
  res.menu = res.menu || DEFAULT_DASHBOARD_MENU;

  // Handle new features
  if (res.menu.chat === undefined) {
    res.menu.chat = DEFAULT_DASHBOARD_MENU.chat;
  }

  return res;
};

export const hasMultipleStreamWidget = (layout, setLayoutActive) => {
  const { menu } = getActiveLayout(layout, setLayoutActive);
  return menu?.stream1 && menu?.stream2;
};

export const getDefaultLayoutConfig = (layout, setLayoutActive) => {
  const layoutConfig = !hasMultipleStreamWidget(layout, setLayoutActive)
    ? DEFAULT_DASHBOARD_LAYOUT
    : DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM;
  return layoutConfig;
};

export const isWidgetVisible = (widget, layout) => {
  const { menu, popout } = layout;
  const menuKey = widget;
  return (
    menu[menuKey] && !(popout || []).find((item) => item.widget === widget)
  );
};

export const isWidgetPopout = (widget, layout) => {
  const { menu, popout } = layout;
  const menuKey = widget;
  return menu[menuKey] && (popout || []).find((item) => item.widget === widget);
};

export const getLayoutForMediaQuery = (media, multipleStream) => {
  let layoutConfig;
  switch (media) {
    case "(min-width: 1400px)":
    case "(max-width: 1399px) and (min-width: 1200px)":
    case "(max-width: 1199px) and (min-width: 992px)": {
      layoutConfig = !multipleStream
        ? DEFAULT_DASHBOARD_LAYOUT
        : DEFAULT_DASHBOARD_LAYOUT_MULTI_STREAM;
      break;
    }
    case "(max-width: 991px) and (min-width: 768px)": {
      layoutConfig = !multipleStream
        ? {
            flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
            stream1: { x: 0, y: 6, w: 12, h: 12, minW: 8, minH: 10 },
            popular: { x: 0, y: 18, w: 4, h: 6, minW: 3, minH: 4 },
            alerts: { x: 4, y: 18, w: 4, h: 6, minW: 3, minH: 4 },
            news: { x: 8, y: 18, w: 4, h: 6, minW: 3, minH: 4 },
            chat: { x: 0, y: 24, w: 12, h: 12, minW: 6, minH: 8 },
            discovery1: { x: 0, y: 36, w: 12, h: 15, minW: 10, minH: 8 },
            discovery2: { x: 0, y: 51, w: 12, h: 15, minW: 10, minH: 8 },
          }
        : {
            flow: { x: 0, y: 0, w: 12, h: 6, minW: 6, minH: 4 },
            stream1: { x: 0, y: 6, w: 8, h: 12, minW: 8, minH: 10 },
            stream2: { x: 0, y: 18, w: 8, h: 12, minW: 8, minH: 10 },
            popular: { x: 8, y: 6, w: 4, h: 8, minW: 3, minH: 4 },
            alerts: { x: 8, y: 14, w: 4, h: 8, minW: 3, minH: 4 },
            news: { x: 8, y: 22, w: 4, h: 8, minW: 3, minH: 4 },
            chat: { x: 0, y: 30, w: 12, h: 12, minW: 6, minH: 8 },
            discovery1: { x: 0, y: 42, w: 12, h: 15, minW: 10, minH: 8 },
            discovery2: { x: 0, y: 57, w: 12, h: 15, minW: 10, minH: 8 },
          };
      break;
    }
    case "(max-width: 767px) and (min-width: 576px)":
    case "(max-width: 575px)": {
      layoutConfig = !multipleStream
        ? {
            flow: { x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
            stream1: { x: 0, y: 4, w: 12, h: 12, minW: 12, minH: 10 },
            popular: { x: 0, y: 16, w: 12, h: 6, minW: 12, minH: 4 },
            alerts: { x: 0, y: 22, w: 12, h: 6, minW: 12, minH: 4 },
            news: { x: 0, y: 28, w: 12, h: 6, minW: 12, minH: 4 },
            chat: { x: 0, y: 34, w: 12, h: 10, minW: 12, minH: 8 },
            discovery1: { x: 0, y: 44, w: 12, h: 15, minW: 12, minH: 8 },
            discovery2: { x: 0, y: 59, w: 12, h: 15, minW: 12, minH: 8 },
          }
        : {
            flow: { x: 0, y: 0, w: 12, h: 4, minW: 6, minH: 4 },
            stream1: { x: 0, y: 4, w: 12, h: 10, minW: 12, minH: 10 },
            stream2: { x: 0, y: 14, w: 12, h: 10, minW: 12, minH: 10 },
            popular: { x: 0, y: 24, w: 12, h: 6, minW: 12, minH: 4 },
            alerts: { x: 0, y: 30, w: 12, h: 6, minW: 12, minH: 4 },
            news: { x: 0, y: 36, w: 12, h: 6, minW: 12, minH: 4 },
            chat: { x: 0, y: 42, w: 12, h: 10, minW: 12, minH: 8 },
            discovery1: { x: 0, y: 52, w: 12, h: 15, minW: 12, minH: 8 },
            discovery2: { x: 0, y: 67, w: 12, h: 15, minW: 12, minH: 8 },
          };
      break;
    }
    // case '(max-width: 575px)': {
    //   layoutConfig = {
    //     stream1: {x: 0, y: 0, w: 12, h: 12, minW: 12, minH: 10},
    //     popular: {x: 0, y: 12, w: 12, h: 5, minW: 12, minH: 4},
    //     alerts: {x: 0, y: 16, w: 12, h: 5, minW: 12, minH: 4},
    //     news: {x: 0, y: 20, w: 12, h: 5, minW: 12, minH: 4},
    //     discovery1: {x: 0, y: 24, w: 12, h: 15, minW: 12, minH: 8}
    //   };
    //   break;
    // }
  }
  return layoutConfig;
};
